import { Navigate } from "react-router-dom";
import { Row, Col, Input, Button, Form } from "antd";

import { useAuth } from "@/hooks/index";

const Login = () => {

  const { handleSignIn, isAuth, isLoading } = useAuth()

  const onFinish = (formData: LoginType) => handleSignIn(formData);

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  if (isAuth) {
    return <Navigate to="/users" replace />;
  }

  return (
    <div className={"login-centered"}>
      <Row justify="center">
        <Col span={24}>
          <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 24 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            layout="vertical"
          >
            <Form.Item
              label="Логин"
              name="login"
              rules={[{ required: true, message: "Введите логин!" }]}
              required
            >
              <Input type="" />
            </Form.Item>

            <Form.Item
              label="Пароль"
              name="password"
              rules={[{ required: true, message: "Введите пароль!" }]}
              required
            >
              <Input.Password />
            </Form.Item>

            <Form.Item wrapperCol={{ span: 24 }}>
              <Button type="primary" htmlType="submit" disabled={isLoading}>
                Войти
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div>
  );
}

export default Login;
