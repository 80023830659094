// @ts-nocheck  FIXME:
import { useEffect, useReducer } from "react";
import { Button, Col, Modal, Row, Space } from "antd";
import EditOutlined from "@ant-design/icons/EditOutlined";
import isEmpty from "lodash/isEmpty";

import { Heading } from "@/components/Heading";
import { PageTable } from "@/components/PageTable";
import { Loader } from "@/components/Loader";
import Main from "./Editor";

import orgReducer from "reducers/orgReducer";
import { useOrganizations, useUpdateOrganization } from "@/hooks/organizations";


const initialState = {
  editorInitValues: { id: null },
  isOpenChange: false
}

const Organizations = () => {

  const [state, dispatch] = useReducer(orgReducer, initialState)

  const { editorInitValues, isOpenChange, id } = state;

  const { loading: isLoading, data: organizations, refresh: refreshOrganizations, run: handleOrgLoad } = useOrganizations({manual: true})

  const { loading: isUpdateLoading, run: handleOrgUpdate } = useUpdateOrganization(
    id,
    {
      manual: true,
      onSuccess: () => dispatch({
        type: 'SET_IS_OPEN_CHANGE',
        payload: false
      }),
    },
  );

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const showChangeModal = async (record: OrgDataType) => {
    await dispatch({
      type: 'SET_EDITOR_INIT_VALUES',
      payload: record
    })
    dispatch({
      type: 'SET_IS_OPEN_CHANGE',
      payload: true
    })
  }

  const columns = [
    {
      title: "Название",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      sorter: (a: any, b: any) => a.id - b.id,
    },
    {
      title: "Client_key",
      dataIndex: "client_key",
      key: "client_key",
    },
    {
      title: "",
      key: "action",
      render: (record: OrgDataType) => {
        const { id } = record
        if (!!id) {
          return (
            <Space size="middle">
              <Button onClick={() => showChangeModal(record)} type="link">
                <EditOutlined />
                Изменить
              </Button>
            </Space>
          )
        }
      },
    }
  ];
  useEffect(() => handleOrgLoad(), [handleOrgLoad])
  
  useEffect(() => {
    refreshOrganizations()

  }, [isUpdateLoading, refreshOrganizations])


  if (isLoading || isUpdateLoading) {
    return <Loader />
  }

  return (
    <Row gutter={[5, 5]}>
      <Col span={24}>
        <Heading title="Организации" />
      </Col>
      <Col span={24}>
        <PageTable data={organizations} columns={columns} />
      </Col>
      {
        !isEmpty(editorInitValues) && (
          <Modal
            open={isOpenChange}
            title="Изменить организацию"
            footer={false}
            onCancel={async () => {
              await dispatch({
                type: 'SET_IS_OPEN_CHANGE',
                payload: false
              })
              dispatch({
                type: 'SET_EDITOR_INIT_VALUES',
                payload: { id: null }
              })
            }}
          >
            <Main
              onFinish={handleOrgUpdate}
              onFinishFailed={onFinishFailed}
              loading={isUpdateLoading}
              setIsOpen={dispatch}
              action={'SET_IS_OPEN_CHANGE'}
              editorInitValues={editorInitValues}
              submitText={'Изменить'}
            />
          </Modal>
        )
      }
    </Row>
  );
};

export default Organizations;

