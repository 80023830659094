import ErrorBoundary from "antd/lib/alert/ErrorBoundary";
import axios, { AxiosInstance, AxiosResponse, AxiosRequestConfig } from "axios";

const baseURL = process.env.REACT_APP_BASE_API_URL ?? "";

class HTTPClient {
	
	protected readonly instance: AxiosInstance;

	public constructor(baseURL: string) {
		this.instance = axios.create({
			baseURL,
			headers: {
				"Content-Type": "application/json",
			},
			withCredentials: true,
		});

		this.initializeRequestInterceptor();
		this.initializeResponseInterceptor();
	}

	private initializeRequestInterceptor = () => {
		this
			.instance
			.interceptors
			.request
			.use(
				this.handleRequest,
				this.handleError,
			);
	};

	private initializeResponseInterceptor = () => {
		this
			.instance
			.interceptors
			.response
			.use(
				this.handleResponse,
				this.handleError,
			);
	};

	private handleRequest = (request: AxiosRequestConfig) => {
		return request
	};

	private handleResponse = async (response: AxiosResponse) => {
		return response;

		// switch(status) {
		// 	case 200:
		// 		const { code, message } = error ?? {};

		// 		switch(code) {
		// 			case 105:
		// 			case 106:
		// 				try {
		// 					console.info("refreshAuthToken", { response })

		// 					const refreshTokenData = await this.request({
		// 						method: "refreshAuthToken",
		// 						params: { "secret_key": this.refreshToken },
		// 					});

		// 					this.token = refreshTokenData?.data?.result?.token;
		// 					this.expiredAt = refreshTokenData?.data?.result?.token_expires;

		// 					return this.instance(response);
		// 				} catch (error) {
		// 					return Promise.reject(error);
		// 				}
		// 			case 10003:
		// 				console.info(10003);

		// 				return Promise.reject(error)
		// 			default:
		// 				return Promise.resolve(response);
		// 		}
		// 	default:
		// 		return Promise.resolve(response);
		// }
	};

	protected handleError = (error: ErrorBoundary) => {
		console.error(error);

		Promise.reject(error)
	};

	public request(options: AxiosRequestConfig) {
		return this.instance(options);
	}

	public get(url: string, options?: AxiosRequestConfig) {
		return this.instance.get(url, options);
	}

	public post(url: string, options?: AxiosRequestConfig) {
		return this.instance.post(url, options);
	}

	public put(url: string, options?: AxiosRequestConfig) {
		return this.instance.put(url, options);
	}

	public delete(url: string, options?: AxiosRequestConfig) {
		return this.instance.delete(url, options);
	}
}

const instance = new HTTPClient(baseURL);

export { HTTPClient };
export default instance;
