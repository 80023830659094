import { createContext, ReactNode, useCallback, useReducer } from "react";

import { role } from "@/constants/constants";
import { useClients, useCheckAuth, useSignIn, useSignOut } from "@/hooks/index";
import { authReducer, Role } from "reducers";


type AuthContextType = {
	isAuth: boolean
	isLoading: boolean
	handleSignIn: (formData: Login) => void
	handleSignOut: () => void
	checkAuth: () => any
	setClient: (client: any) => void
	clients: Array<{ key: string }>
	client: string
	role: Role | null
}

const initialState = {
	isAuth: false,
	clients: [],
	isLoading: false,
	client: '',
	formData: { login: '', password: '' }
}

export const AuthContext = createContext<AuthContextType>(null!);

export default function AuthProvider({ children }: { children: ReactNode }) {

	const [state, dispatch] = useReducer(authReducer, initialState)

	const { loading: isAuthLoading, run: handleSignIn } = useSignIn(
		{
			manual: true,
			onSuccess: (data: boolean) => dispatch({
				type: 'SET_IS_AUTH',
				payload: data
			})
		}
	)

	const { loading: isSignOutLoading, run: handleSignOut } = useSignOut(
		{
			manual: true,
			onSuccess: (data: boolean) => dispatch({
				type: 'SET_IS_AUTH',
				payload: data
			})
		}
	)

	const { loading: checkAuthLoading, run: checkAuth } = useCheckAuth(
		{
			manual: true,
			onSuccess: () => dispatch({
				type: 'SET_IS_AUTH',
				payload: state.isAuth
			})
		}
	)

	const { loading: isClientsLoading } = useClients( 
		{
			ready: state.isAuth,
			onSuccess: (data: Array<{ key: string }>) => dispatch({
				type: 'SET_CLIENTS',
				payload: data
			}),
		}
	)

	const setClient = useCallback((client: string) => {
		// refresh http update instance
		dispatch({
			type: 'SET_CLIENT',
			payload: client
		})
	}, [])

	const value = {
		isAuth: state.isAuth,
		isLoading: isAuthLoading || isSignOutLoading || isClientsLoading || checkAuthLoading,
		clients: state.clients,
		role: role,
		handleSignOut,
		checkAuth,
		client: state.client,
		setClient,
		handleSignIn,
	};
	// @ts-ignore
	return <AuthContext.Provider value={value}>
		{children}
	</AuthContext.Provider>;
}
