import React, { FC, useEffect, useRef, useState } from "react";
import { Layout, Menu, Row, Col, Button, Drawer } from "antd";
import { Link, useNavigate } from "react-router-dom";
import LogoutOutlined from "@ant-design/icons/LogoutOutlined";

import { Logo } from "./Logo";
import { MobileMenu } from "./MobileMenu";
import { DesktopMenu } from "./DesktopMenu";
import { useSize } from "ahooks";
import { useAuth } from "@/hooks/index";

const { Header } = Layout;

const PageLayout: FC<PageLayoutClientProps> = ({ children },) => {
  const { isAuth, handleSignOut, role } = useAuth();

  const ref = useRef(null);

  const [isOpenMenu, setOpenMenu] = useState<boolean>(false)
  const [isOpenProfile, setOpenProfile] = useState<boolean>(false)
  const [menuItems, setMenuItems] = useState<Array<MenuItemsType>>([])

  const navigate = useNavigate();

  const signOut = () => {
    setOpenProfile(false)
    handleSignOut();
    navigate("/login")
  }

  useEffect(() => {
    if (isOpenMenu) {
      setOpenProfile(false)
    }
  }, [isOpenMenu])

  const size = useSize(ref);
  let layoutWidth = 768

  if (size?.width) {
    layoutWidth = size.width
  }

  useEffect(() => {
    if (role === 'admin') {
      setMenuItems([
        { label: "Клиенты", key: 1, link: "./users" },
        { label: "Организации", key: 4, link: "./organizations" },
        { label: "Документы", key: 2, link: "./documents" },
      ])
    }

    if (role === 'client') {
      setMenuItems([
        { label: "Пользователи", key: 1, link: "./users" },
        { label: "Документы", key: 2, link: "./documents" },
        { label: "Шаблоны документов", key: 3, link: "./templates" },
      ])
    }
  }, [role])

  return (
    <div ref={ref} className="font-face-os">
      <Layout className="layout"  >
        <Header style={{ zIndex: 1001 }}>
          <Row justify="space-between" style={{ height: '100%' }}>
            <Col flex={"100px"} style={{ display: "flex", alignItems: "center" }}>
              <Logo />
            </Col>
            <Col flex={'auto'}>
              {
                isAuth && (layoutWidth <= 800) && (
                  <MobileMenu
                    isOpenMenu={isOpenMenu}
                    isOpenProfile={isOpenProfile}
                    setOpenMenu={setOpenMenu}
                    setOpenProfile={setOpenProfile}
                  />
                )
              }
              {
                isAuth && layoutWidth > 800 && (
                  <DesktopMenu
                    menuItems={menuItems}
                    onClick={signOut}
                  />
                )
              }
            </Col>
          </Row>
          <Drawer
            title="Basic Drawer"
            placement="top"
            closable={false}
            onClose={() => setOpenMenu(false)}
            open={isOpenMenu}
            contentWrapperStyle={{ height: 250 }}
            maskStyle={{ backgroundColor: '#00000005' }}
          >

            <Menu
              style={{ background: "none" }}
              mode="inline"
              defaultSelectedKeys={["1"]}
              theme='dark'
              className="mobile-menu"
            >
              {
                menuItems.map(({ label, key, link }) => {
                  return (
                    <Menu.Item key={key} style={{ color: "black" }} onClick={() => setOpenMenu(false)}>
                      <Link to={link}>{label}</Link>
                    </Menu.Item>
                  );
                })
              }
            </Menu>
          </Drawer>
          <Drawer
            title="Basic Drawer"
            placement="top"
            closable={false}
            onClose={() => setOpenProfile(false)}
            open={isOpenProfile}
            contentWrapperStyle={{ height: 'minContent' }}
            maskStyle={{ backgroundColor: '#00000005' }}
          >
            <Row>
              <Col span={24}>
                <Button
                  type="link"
                  icon={<LogoutOutlined />}
                  onClick={signOut}
                  block
                  style={{ color: "#8D7DED" }}
                >
                  Выход
                </Button>
              </Col>
            </Row>
          </Drawer>
        </Header>
        {children}
      </Layout>
    </div>
  );
};

export default PageLayout;
