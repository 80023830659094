import { Col, Row, Table } from "antd";

const PageTable: React.FC<{ columns: Array<{}>, data: Array<{}> }> = ({
  columns = [],
  data = [],
}) => {

  return (
    <Row gutter={[10, 10]}>
      <Col span={24}>
        <Table
          columns={columns}
          dataSource={data}
          scroll={{ x: 600 }}
          pagination={{ position: ['topRight', 'bottomRight'] }}
        />
      </Col>
    </Row>
  );
};

export default PageTable;
