import { useRequest } from "ahooks";


import service from "@/api/HTTPClient";

const useUpdateOrganization = (id: number, options: ObjectRecord) => {

	const request = (data: UserDataType) => {

		if (id) {
			return new Promise((resolve, reject) => {
				// @ts-ignore
				service.put(`organizations/${id}`, data)
					.then(response => resolve(response.status))
					.catch(reject)
			})
		}

		return Promise.reject(new Error('Ошибка обновления организации'))
	}
	// @ts-ignore
	return useRequest(request, options)
}

export default useUpdateOrganization;
