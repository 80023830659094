import { useRequest } from "ahooks";
import service from "@/api/HTTPClient";

const useCheckAuth = (options: ObjectRecord) => {

	const request = () => {
		return new Promise((resolve, reject) => {
			service.get(`sessions`)
				.then(response => resolve(response.status === 200))
				.catch(reject)
		})
	}

	return useRequest(request, options)
}

export default useCheckAuth;
