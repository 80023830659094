import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";

import "./style.less";

import { Documents } from "./components/Pages/Documents";
import { PageLayout } from "./components/Layout";
import { Users } from "./components/Pages/Users";
import { DocTemplates } from "./components/Pages/DocTemplates";
import { Login } from "./components/Pages/Login";
import { Content } from "antd/lib/layout/layout";
import { Loader } from "./components/Loader";
import { Organizations } from "./components/Pages/Organizations";
import { useAuth } from "@/hooks/index";
import { AuthProvider } from "./components/Providers/AuthProvider";

function App() {

	return (
		<Router>
			<AuthProvider>
				<PageLayout>
					<Content className="container">
						<Routes>
							<Route
								path="/"
								element={
									<RequireAuth>
										<Users />
									</RequireAuth>
								}
							/>
							<Route
								path="/users"
								element={
									<RequireAuth>
										<Users />
									</RequireAuth>
								}
							/>
							{/* { role === 'admin' && */} <Route
								path="/organizations"
								element={
									<RequireAuth>
										<Organizations />
									</RequireAuth>
								}
							/>
							<Route
								path="/templates"
								element={
									<RequireAuth>
										<DocTemplates />
									</RequireAuth>
								}
							/>
							<Route
								path="/documents"
								element={
									<RequireAuth>
										<Documents />
									</RequireAuth>
								}
							/>
							<Route
								path="/login"
								element={
									<Login />
								}
							/>
						</Routes>
					</Content>
				</PageLayout>
			</AuthProvider>
		</Router>
	);
}

export default App;

function RequireAuth({ children }: { children: JSX.Element }) {

	const { isAuth, checkAuth, isLoading, role } = useAuth();

	useEffect(() => {
			checkAuth()

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	if (isLoading) {
		return <Loader />
	}

	if (children.type.name === 'Organizations' && role !== 'admin') {
		return <Navigate to="/" replace />
	}

	if (isAuth) {
		return <>{children}</>
	}

	return <Navigate to="/login" replace />
}
