import { useRequest } from "ahooks";

import service from "@/api/HTTPClient";
import { useAuth } from "@/hooks/index";

const useCreateUser = (options: ObjectRecord) => {
	const { client } = useAuth()

	const request = (data: UserDataType) => {

		return new Promise((resolve, reject) => {
			// @ts-ignore
			service.post(`${client}/admin/users`, data)
				.then(response => resolve(response.status))
				.catch(reject)
		})
	}

	return useRequest(request, options)
}

export default useCreateUser;
