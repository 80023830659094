import { useRequest } from "ahooks";
import service from "@/api/HTTPClient";

const useSignIn = (options: ObjectRecord) => {

	const request = ({ login, password }: Login) => {
		return new Promise((resolve, reject) => {
			// @ts-ignore
			service.post(`sessions`, { login, password })
				.then(response => resolve(response.status === 200))
				.catch(reject)
		})
	}

	return useRequest(request, options)
}

export default useSignIn;
