
import { Button, Form, Input } from "antd";

const OrgEditor = ({ onFinish, onFinishFailed, loading, setIsOpen, editorInitValues, submitText = "Создать", action }: EditorTypes) => {

	return (
		<Form
			labelCol={{ span: 4 }}
			wrapperCol={{ span: 14 }}
			layout="vertical"
			onFinish={onFinish}
			onFinishFailed={onFinishFailed}
			initialValues={editorInitValues}
		>
			<Form.Item
				label="Название организации"
				labelCol={{ span: 24 }}
				name={"name"}
				rules={[{ required: true, message: 'Введите название организации' }]}
			>
				<Input />
			</Form.Item>
			<Form.Item wrapperCol={{ span: 24 }}>
				<Button type="primary" htmlType="submit" disabled={loading}>
					{submitText}
				</Button>
				<Button type="ghost" disabled={loading} onClick={() => setIsOpen({
					type: action,
					payload: false
				})}>
					Отмена
				</Button>
			</Form.Item>
		</Form>
	)
}

export default OrgEditor
