import { useEffect, useMemo, useReducer } from "react";
import { Button, Col, Modal, Popconfirm, Row, Select, Space, Tag } from "antd";
import DeleteOutlined from "@ant-design/icons/DeleteOutlined";
import EditOutlined from "@ant-design/icons/EditOutlined";
import isEmpty from "lodash/isEmpty";

import { Heading } from "@/components/Heading";
import { PageTable } from '@/components/PageTable'
import { Loader } from "@/components/Loader";
import UserEditor from "./UserEditor";

import { useAuth } from "@/hooks/index";
import { useUsers, useUpdateUser, useCreateUser, useDeleteUser } from "@/hooks/user";
import { userReducer } from "reducers";

const initialState = {
  editorInitValues: { id: null },
  isOpenCreate: false,
  isOpenChange: false,
  id: null
}

const Users = () => {
  const { clients, client, setClient, role } = useAuth()

  const [state, dispatch] = useReducer(userReducer, initialState)

  const { editorInitValues, isOpenCreate, isOpenChange, id } = state
  console.log(isOpenChange);

  const { loading: isLoading, data: users, refresh, run: handleUserLoad } = useUsers({manual: true})

  const { loading: isCreateLoading, run: handleUserCreate } = useCreateUser(
    {
      onSuccess: () => dispatch({
        type: 'SET_IS_OPEN_CREATE',
        payload: false
      }),
      manual: true
    }
  );

  const { loading: isUpdateLoading, run: handleUserUpdate } = useUpdateUser(
    // @ts-ignore FIXME:
    id,
    {
      manual: true,
      onSuccess: () => dispatch({
        type: 'SET_IS_OPEN_CHANGE',
        payload: false
      }),
    },
  );

  const { loading: isDeleteLoading, run: handleUserDelete } = useDeleteUser({ manual: true });

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const showChangeModal = async (record: UserDataType) => {
    await dispatch({
      type: 'SET_EDITOR_INIT_VALUES',
      payload: record
    })
    dispatch({
      type: 'SET_IS_OPEN_CHANGE',
      payload: true
    })
  }

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "UserId",
      sorter: (a: any, b: any) => a.id - b.id,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Роль",
      dataIndex: "roles",
      key: "roles",
      render: (_: string[], { roles }: { roles: string[] }) => (
        <>
          {
            roles.map((role) => <span style={{ marginRight: 5 }}>{role}</span>)
          }
        </>
      )
    },
    {
      title: "Статус",
      key: "status",
      dataIndex: "state",
      sorter: (a: any, b: any) => a.state.length - b.state.length,
      render: (status: string) => {
        const color = (status === "active") ? "green" : "red";
        return (
          <span>
            <Tag color={color} key={status}>
              {status}
            </Tag>
          </span>
        );
      },
    },
    {
      title: "",
      key: "action",
      render: (record: UserDataType) => {

        if (!!record?.id) {
          const id = record.id
          return (
            <Space size="middle">
              <Button onClick={() => showChangeModal(record)} type="link">
                <EditOutlined />
                Изменить
              </Button>
              <Popconfirm placement="left" title={'Вы тоычно хотите удалить пользователя?'} onConfirm={() => handleUserDelete(id)} okText="Да" cancelText="Нет">
                <Button type="link">
                  <DeleteOutlined />
                  Удалить
                </Button>
              </Popconfirm>
            </Space>
          )
        }
      },
    }
  ];

  const roleOptions = [
    {
      label: "manager",
      value: "manager",
    },
    {
      label: "admin",
      value: "admin",
    },
  ];

  const statusOptions = [
    {
      label: "Активен",
      value: "active",
    },
    {
      label: "Заблокирован",
      value: "block",
    },
  ];

  const clientsOptions = useMemo(() => {
    if (!!clients) {
      return clients?.map(({ key }: { key: string }) => {
        return {
          value: key,
          label: key,
        }
      })
    }

    return []
  }, [clients])

  useEffect(() => {

    const clientKey = !!clientsOptions.length ? clientsOptions?.[0].value : ''
    setClient(clientKey)

    if (!!clientKey) {
      handleUserLoad()
    }

  }, [clientsOptions, handleUserLoad, setClient])

  useEffect(() => {
    if (client) {
      refresh()
    }
  }, [isCreateLoading, isUpdateLoading, isDeleteLoading, refresh, client])


  if (!client || isLoading) {
    return <Loader />
  }

  return (
    <Row gutter={[5, 5]}>
      <Col span={24}>
        <Heading
          title="Пользователи"
          buttonTitle="Добавить"
          onClick={() => dispatch({
            type: 'SET_IS_OPEN_CREATE',
            payload: true
          })}
        />
      </Col>
      {
        role === 'client' && (
          <Col xs={24} lg={12} xl={6}>
            <Select
              style={{ width: "100%" }}
              placeholder="Please select"
              defaultValue={client}
              options={clientsOptions}
              onSelect={setClient}
              disabled={clientsOptions.length === 1}
            />
          </Col>
        )
      }
      <Col span={24}>
        {/* @ts-ignore  TODO: need fix */}
        <PageTable data={users} columns={columns} />
      </Col>
      <Modal
        open={isOpenCreate}
        title="Новый пользователь"
        onCancel={() => dispatch({
          type: 'SET_IS_OPEN_CREATE',
          payload: false
        })}
        footer={false}
      >
        <UserEditor
          onFinish={handleUserCreate}
          onFinishFailed={onFinishFailed}
          roleOptions={roleOptions}
          statusOptions={statusOptions}
          loading={isCreateLoading}
          setIsOpen={dispatch}
          editorInitValues={{}}
          action={'SET_IS_OPEN_CREATE'}
        />
      </Modal>
      {
        !isEmpty(editorInitValues) && (
          <Modal
            open={isOpenChange}
            title="Изменить пользователя"
            footer={false}
            onCancel={async () => {
              await dispatch({
                type: 'SET_IS_OPEN_CHANGE',
                payload: false
              })
              dispatch({
                type: 'SET_EDITOR_INIT_VALUES',
                payload: { id: null }
              })
            }}
            destroyOnClose
          >
            <UserEditor
              onFinish={handleUserUpdate}
              onFinishFailed={onFinishFailed}
              roleOptions={roleOptions}
              statusOptions={statusOptions}
              loading={isUpdateLoading}
              setIsOpen={dispatch}
              action={'SET_IS_OPEN_CHANGE'}
              editorInitValues={editorInitValues}
              submitText={'Изменить'}
            />
          </Modal>
        )
      }
    </Row>
  );
};

export default Users;
