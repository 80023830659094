
type SetEditorInitValuesAction = {
	type: 'SET_EDITOR_INIT_VALUES'
	payload: OrgDataType
}

type SetIsOpenChangeAction = {
	type: 'SET_IS_OPEN_CHANGE'
	payload: boolean
}

type Action = SetEditorInitValuesAction | SetIsOpenChangeAction

type State = {
	editorInitValues: OrgDataType | {}
	isOpenChange: boolean
	id: number | null
}

const orgReducer = (state: State, { type, payload }: Action) => {
	switch (type) {
		case 'SET_EDITOR_INIT_VALUES':
			return {
				...state,
				editorInitValues: payload,
				id: payload.id,
			}
		case 'SET_IS_OPEN_CHANGE':
			return {
				...state,
				isOpenChange: payload
			}
		default:
			return state
	}
}

export default orgReducer;
