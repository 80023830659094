import { useEffect, useMemo } from "react";
import { Col, Row, Select, Tag } from "antd";

import { PageTable } from "@/components/PageTable";
import { Heading } from "@/components/Heading";
import { Loader } from "@/components/Loader";

import { useAuth } from "@/hooks/index";
import { useDocuments } from "@/hooks/documents";

const Documents = () => {

  const { clients, client, setClient, role } = useAuth()

  const { loading: isLoading, data: documents, refresh: documentsRefresh, run: handleDocumentsLoad } = useDocuments({ manual: true })

  const columns = [
    {
      title: "Название",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Ключ",
      dataIndex: "key",
      key: "key",
    },
    {
      title: "Client_key",
      dataIndex: "clientKey",
      key: "clientKey"
    },
    {
      title: "Template_key",
      dataIndex: "templateKey",
      key: "templateKey",
      ellipsis: true,
    },
    {
      title: "Signature_key",
      dataIndex: "signatureKey",
      key: "signatureKey",
      ellipsis: true,
    },
    {
      title: "File_url",
      dataIndex: "fileUrl",
      key: "fileUrl",
      ellipsis: true,
      render: (text: string) => <span>{text}</span>,
    },
    {
      title: "Статус",
      key: "state",
      dataIndex: "state",
      render: (state: string) => {
        let color = 'green'
        if (state === 'Отклонен') {
          color = 'red'
        }
        if (state === 'new') {
          color = 'yellow'
        }
        return (
          <span>
            <Tag color={color} key={state}>
              {state}
            </Tag>
          </span>
        );
      },
    },
  ];

  const clientsOptions = useMemo(() => {
    if (!!clients) {
      return clients?.map(({ key }: { key: string }) => {
        return {
          value: key,
          label: key,
        }
      })
    }

    return []
  }, [clients])

  useEffect(() => {

    const clientKey = !!clientsOptions.length ? clientsOptions?.[0].value : ''
    setClient(clientKey)

    if (!!clientKey) {
      handleDocumentsLoad()
    }

  }, [clientsOptions, handleDocumentsLoad, setClient])

  useEffect(() => {
    if (client) {
      documentsRefresh()
    }
  }, [documentsRefresh, client])

  if (!client || isLoading) {
    return <Loader />
  }

  return (
    <Row gutter={[5, 5]}>
      <Col span={24}>
        <Heading title="Документы" />
      </Col>
      {
        role === 'client' && (
          <Col xs={24} lg={12} xl={6}>
            <Select
              style={{ width: "100%" }}
              placeholder="Please select"
              defaultValue={client}
              options={clientsOptions}
              onSelect={setClient}
              disabled={clientsOptions.length === 1}
            />
          </Col>
        )
      }
      <Col span={24}>
        {/* @ts-ignore */}
        <PageTable data={documents} columns={columns} />
      </Col>
    </Row>
  );
};

export default Documents;
