import { MaskedInput } from "antd-mask-input";
import React from "react";

const DynamicPhone = (props: any) => {
	const cellphoneMask = '+7(000) 000-00-00';
	const phoneMask = '+7(000) 000-00-00';

	const mask = React.useMemo(
		() => [
			{
				mask: cellphoneMask,
				lazy: false,
			},
			{
				mask: phoneMask,
				lazy: false,
			},
		],
		[]
	);

	return (
		<MaskedInput
			{...props}
			mask={mask}
			maskOptions={{
				dispatch: function (appended, dynamicMasked) {
					const isCellPhone = dynamicMasked.unmaskedValue[2] === '9';
					return dynamicMasked.compiledMasks[isCellPhone ? 0 : 1];
				},
			}}
		/>
	);
};

export default DynamicPhone
