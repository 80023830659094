import { useRequest } from "ahooks";

import service from "@/api/HTTPClient";
import { useAuth } from "@/hooks/index";

const useDeleteUser = (options: ObjectRecord) => {

	const { client } = useAuth()

	const request = (id: number) => {
		if (id) {
			return new Promise((resolve, reject) => {
				service.delete(`${client}/admin/users/${id}`)
					.then(response => resolve(response.status))
					.catch(reject)
			})
		}

		return Promise.reject(new Error('Ошибка удаления пользователя'))
	}
	// @ts-ignore
	return useRequest(request, options)
}

export default useDeleteUser;
