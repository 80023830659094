
import { Button, Form, Input, Select } from "antd";

import DynamicPhone from "@/components/Form/DynamicPhone";

const UserEditor = ({ onFinish, onFinishFailed, roleOptions, statusOptions, loading, setIsOpen, editorInitValues, submitText = "Создать", action }: EditorTypes) => {

	return (
		<Form
			labelCol={{ span: 4 }}
			wrapperCol={{ span: 14 }}
			layout="vertical"
			onFinish={onFinish}
			onFinishFailed={onFinishFailed}
			initialValues={editorInitValues}
		>
			<Form.Item
				label="Email"
				labelCol={{ span: 24 }}
				name={"email"}
				rules={[{ required: true, message: 'Введите email' }]}
			>
				<Input />
			</Form.Item>
			<Form.Item
				label="Пароль"
				required
				labelCol={{ span: 24 }}
				name={"password_digest"}
				rules={[{ required: true, message: 'Введите пароль' }]}
			>
				<Input.Password />
			</Form.Item>
			<Form.Item
				label="Тедефон"
				required
				labelCol={{ span: 24 }}
				name={"phone"}
				rules={[{ required: true, message: 'Введите номер телефона' }]}
			>
				<DynamicPhone />
			</Form.Item>
			<Form.Item
				label="Роли"
				required
				labelCol={{ span: 24 }}
				name={"roles"}
				rules={[{ required: true, message: 'Укажите роли' }]}
			>
				<Select
					mode="multiple"
					style={{ width: "100%" }}
					placeholder="Please select"
					options={roleOptions}
				/>
			</Form.Item>
			<Form.Item
				label="Статус"
				required
				labelCol={{ span: 24 }}
				name={"state"}
				rules={[{ required: true, message: 'Установите статус' }]}
			>
				<Select
					style={{ width: "100%" }}
					placeholder="Please select"
					options={statusOptions}
				/>
			</Form.Item>
			<Form.Item wrapperCol={{ span: 24 }}>
				<Button type="primary" htmlType="submit" disabled={loading}>
					{submitText}
				</Button>
				<Button type="ghost" disabled={loading} onClick={() => setIsOpen({
					type: action,
					payload: false
				})}>
					Отмена
				</Button>
			</Form.Item>
		</Form>
	)
}

export default UserEditor
