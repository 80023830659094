const Loader = () => {
	return (

		<div className="spin-wrapper">
			<div className="spinner" />
		</div>

	)
}

export default Loader
