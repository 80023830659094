import LogoutOutlined from "@ant-design/icons/LogoutOutlined";
import UserOutlined from "@ant-design/icons/UserOutlined";
import { Button, Col, Dropdown, Menu, Row } from "antd";
import { Link } from "react-router-dom";




const DesktopMenu = ({ menuItems, onClick }: DesktopMenuType) => {

	const items: any = [
		{
			key: '1',
			label: (
				<Button
					type="link"
					onClick={onClick}
					block
					style={{ color: "black" }}
				>
					<Row gutter={[10, 10]} align="middle">
						<Col>
							<LogoutOutlined style={{ fontSize: "22px" }} />
						</Col>
						<Col>
							Выход
						</Col>
					</Row>
				</Button>
			),
		},
	]

	return (
		<Row justify='space-between'>
			<Col flex='auto'>
				<Menu
					style={{ background: "none" }}
					mode="horizontal"
					defaultSelectedKeys={["1"]}
					inlineCollapsed={false}
				>
					{
						menuItems.map(({ label, key, link }) => {
							return (
								<Menu.Item key={key}>
									<Link to={link}>{label}</Link>
								</Menu.Item>
							);
						})
					}
				</Menu>
			</Col>
			<Col
				style={{
					display: "flex",
					justifyContent: "end",
					alignItems: "center",
				}}
			>
				<Dropdown menu={{ items }} placement="bottomLeft" arrow trigger={['click']}>
					<Row gutter={[10, 10]}>
						<Col style={{ display: 'flex', alignItems: "center" }}>
							<UserOutlined style={{ fontSize: 32 }} />
						</Col>
						<Col>client</Col>
					</Row>
				</Dropdown>
			</Col>
		</Row>
	)
}

export default DesktopMenu;
