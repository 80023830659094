import { useEffect, useMemo, useReducer } from "react";
import {
  Button,
  Col,
  Modal,
  Popconfirm,
  Row,
  Select,
  Space,
} from "antd";
import DeleteOutlined from "@ant-design/icons/DeleteOutlined";
import EditOutlined from "@ant-design/icons/EditOutlined";
import isEmpty from "lodash/isEmpty";

import { PageTable } from "@/components/PageTable";
import { Heading } from "@/components/Heading";
import { Loader } from "@/components/Loader";
import DocTemplateEditor from "./DocTemplateEditor";

import { useAuth } from "@/hooks/index";
import {
  useCreateDocTemplate,
  useDeleteDoctemplate,
  useDocTemplates,
  useUpdateDocTemplate
} from "@/hooks/docTemplate";
import { docTemplatesReducer } from "reducers";

const initialState = {
  editorInitValues: { id: null },
  isOpenCreate: false,
  isOpenChange: false,
  id: null
}

const formatOptions = [
  {
    label: "html",
    value: "html",
  },
  {
    label: "markdown",
    value: "markdown",
  },
]

const DocTemplates = () => {
  const { clients, client, setClient } = useAuth()
  const [state, dispatch] = useReducer(docTemplatesReducer, initialState)

  const { editorInitValues, isOpenCreate, isOpenChange, id } = state

  const { loading: isLoading, data: docTemplates, refresh: refreshDocTemplates, run: handleDocTemplateLoad } = useDocTemplates({manual: true})

  const { loading: isCreateLoading, run: handleCreateDocTemplate } = useCreateDocTemplate(
    {
      onSuccess: () => dispatch({
        type: 'SET_IS_OPEN_CREATE',
        payload: false
      }),
      manual: true
    }
  );

  const { loading: isUpdateLoading, run: handleDocTemplateUpdate } = useUpdateDocTemplate(
    id,
    {
      manual: true,
      onSuccess: () => dispatch({
        type: 'SET_IS_OPEN_CHANGE',
        payload: false
      }),
    },
  );

  const { loading: isDeleteLoading, run: handleDocTemplateDelete } = useDeleteDoctemplate({ manual: true });

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const showChangeModal = async (record: DocTempDataType) => {
    await dispatch({
      type: 'SET_EDITOR_INIT_VALUES',
      payload: record
    })
    dispatch({
      type: 'SET_IS_OPEN_CHANGE',
      payload: true
    })
  }

  const columns = [
    {
      title: "Имя файла",
      dataIndex: "filename",
      key: "filename",
    },
    {
      title: "Ключ",
      dataIndex: "key",
      key: "key",
    },
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      sorter: (a: any, b: any) => a.id - b.id,
    },
    {
      title: "Client_id",
      dataIndex: "client_id",
      key: "client_id",
    },
    {
      title: "Формат",
      dataIndex: "render_format",
      key: "format",
    },
    {
      title: "Path",
      dataIndex: "path",
      key: "path",
      ellipsis: true,
    },
    {
      title: "",
      key: "action",
      render: (record: DocTempDataType) => {
        const { id } = record
        if (!!id) {
          return (
            <Space size="middle">
              <Button onClick={() => showChangeModal(record)} type="link">
                <EditOutlined />
                Изменить
              </Button>
              <Popconfirm placement="left" title={'Вы тоычно хотите удалить шаблон?'} onConfirm={() => handleDocTemplateDelete(id)} okText="Да" cancelText="Нет">
                <Button type="link">
                  <DeleteOutlined />
                  Удалить
                </Button>
              </Popconfirm>
            </Space>
          )
        }
      },
    }
  ];

  const clientsOptions = useMemo(() => {
    if (!!clients) {
      return clients?.map(({ key }: { key: string }) => {
        return {
          value: key,
          label: key,
        }
      })
    }

    return []
  }, [clients])

  useEffect(() => {

    const clientKey = !!clientsOptions.length ? clientsOptions?.[0].value : ''
    setClient(clientKey)

    if (!!clientKey) {
      handleDocTemplateLoad()
    }

  }, [clientsOptions, handleDocTemplateLoad, setClient])

  useEffect(() => {
    if (client) {
      refreshDocTemplates()
    }
  }, [refreshDocTemplates, client, isCreateLoading, isUpdateLoading, isDeleteLoading])


  if (!client || isLoading) {
    return <Loader />
  }

  return (
    <Row gutter={[5, 5]}>
      <Col span={24}>
        <Heading
          title="Пользователи"
          buttonTitle="Добавить"
          onClick={() => dispatch({
            type: 'SET_IS_OPEN_CREATE',
            payload: true
          })}
        />
      </Col>
      <Col xs={24} lg={12} xl={6}>
        <Select
          style={{ width: "100%" }}
          placeholder="Please select"
          defaultValue={client}
          options={clientsOptions}
          onSelect={setClient}
          disabled={clientsOptions.length === 1}
        />
      </Col>
      <Col span={24}>
        {/* @ts-ignore  TODO: need fix */}
        <PageTable data={docTemplates} columns={columns} />
      </Col>
      <Modal
        open={isOpenCreate}
        title="Новый шаблон"
        onCancel={() => dispatch({
          type: 'SET_IS_OPEN_CREATE',
          payload: false
        })}
        footer={false}
      >
        <DocTemplateEditor
          onFinish={(formData: DocTempDataType) => handleCreateDocTemplate({
            ...formData,
            rjsf: { schema: JSON.parse(formData.rjsf.schema) },
            rjsf_signer: { schema: JSON.parse(formData.rjsf_signer.schema) }
          })
          }
          onFinishFailed={onFinishFailed}
          loading={isCreateLoading}
          setIsOpen={dispatch}
          formatOptions={formatOptions}
          editorInitValues={{}}
          action={'SET_IS_OPEN_CREATE'}
        />
      </Modal>
      {
        !isEmpty(editorInitValues) && (
          <Modal
            open={isOpenChange}
            title="Изменить шаблон"
            footer={false}
            onCancel={async () => {
              await dispatch({
                type: 'SET_IS_OPEN_CHANGE',
                payload: false
              })
              dispatch({
                type: 'SET_EDITOR_INIT_VALUES',
                // @ts-ignore
                payload: { id: null }
              })
            }}
          >
            <DocTemplateEditor
              onFinish={(formData: DocTempDataType) => handleDocTemplateUpdate({
                ...formData,
                rjsf: { schema: JSON.parse(formData.rjsf.schema) },
                rjsf_signer: { schema: JSON.parse(formData.rjsf_signer.schema) },
              })
              }
              onFinishFailed={onFinishFailed}
              loading={isUpdateLoading}
              setIsOpen={dispatch}
              action={'SET_IS_OPEN_CHANGE'}
              editorInitValues={editorInitValues}
              formatOptions={formatOptions}
              submitText={'Изменить'}
            />
          </Modal>
        )
      }
    </Row>
  );
};

export default DocTemplates;
