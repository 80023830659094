import { useRequest } from "ahooks";

import service from "@/api/HTTPClient";

const useOrganizations = (options: ObjectRecord) => {

	const request = () => {
			return new Promise((resolve, reject) => {
				service.get('/organizations')
					.then(response => resolve(response?.data ?? []))
					.catch(reject)
			})
	}
	// @ts-ignore
	return useRequest(request, options)
}

export default useOrganizations;
