import { useRequest } from "ahooks";

import service from "@/api/HTTPClient";
import { useAuth } from "@/hooks/index";

const useCreateDocTemplate = (options: ObjectRecord) => {
	const { client } = useAuth()

	const request = (data: DocTempDataType) => {
		
		return new Promise((resolve, reject) => {
			// @ts-ignore
			service.post(`${client}/admin/document_templates`, data)
				.then(response => resolve(response))
				.catch(reject)
		})
	}

	return useRequest(request, options)
}

export default useCreateDocTemplate;
