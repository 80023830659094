import UserOutlined from "@ant-design/icons/UserOutlined"
import { Button, Col, Row } from "antd"
import { Squash as Hamburger } from 'hamburger-react'

const MobileMenu = ({ isOpenMenu, setOpenMenu, isOpenProfile, setOpenProfile }: MobileMenuType) => {
	return (
		<Row justify='end'>
			<Col>
				<Row style={{ display: 'flex', justifyContent: 'end' }}>
					<Col style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
						<Hamburger toggled={isOpenMenu} toggle={setOpenMenu} rounded />
					</Col>
					<Col>
						<Button type='text' onClick={() => {
							setOpenProfile(!isOpenProfile)
							setOpenMenu(false)
						}}
							style={{ height: '100%', backgroundColor: 'transparent', color: isOpenProfile ? 'gray' : 'white' }}
						>
							<UserOutlined style={{ fontSize: 32 }} />
						</Button>
					</Col>
				</Row>
			</Col>
		</Row>
	)
}

export default MobileMenu;
