
import { Button, Form, Input, Select } from "antd";
import TextArea from "antd/lib/input/TextArea";
// import Form from '@/components/Form'; 

const DocTemplateEditor = ({ onFinish, editorInitValues, loading, setIsOpen, formatOptions, submitText = 'Создать', action }: EditorTypes) => {

const init = {
	...editorInitValues,
	rjsf: {
		schema: JSON.stringify(editorInitValues.rjsf.schema, null, '\t')
	},
	rjsf_signer: {
		schema: JSON.stringify(editorInitValues.rjsf_signer.schema, null, '\t')
	}
}

	return (
		<Form
			labelCol={{ span: 4 }}
			wrapperCol={{ span: 24 }}
			layout="vertical"
			onFinish={onFinish}
			initialValues={init}
		>
			<Form.Item
				label="Имя файла"
				required
				labelCol={{ span: 24 }}
				name={"filename"}
				rules={[{ required: true, message: 'Введите имя файла' }]}
			>
				<Input />
			</Form.Item>
			<Form.Item
				label="Название"
				required
				labelCol={{ span: 24 }}
				name={"name"}
				rules={[{ required: true, message: 'Введите имя' }]}
			>
				<Input />
			</Form.Item>
			<Form.Item
				label="Ключ"
				required
				labelCol={{ span: 24 }}
				name={"key"}
				rules={[{ required: true, message: 'Введите ключ' }]}
			>
				<Input />
			</Form.Item>

			<Form.Item
				label="Формат"
				required
				labelCol={{ span: 24 }}
				name={"render_format"}
				rules={[{ required: true, message: 'Введите формат' }]}
			>
				<Select
					style={{ width: "100%" }}
					placeholder="Please select"
					options={formatOptions}
				/>
			</Form.Item>
			<Form.Item
				label="templater"
				required
				labelCol={{ span: 24 }}
				name={"templater"}
				rules={[{ required: true, message: 'Обязательное поле' }]}
			>
				<Input />
			</Form.Item>
			<Form.Item
				label="path"
				required
				labelCol={{ span: 24 }}
				name={"path"}
				rules={[{ required: true, message: 'Обязательное поле' }]}
			>
				<Input />
			</Form.Item>
			<Form.Item
				label="Текст"
				required
				labelCol={{ span: 24 }}
				name={"text"}
				rules={[{ required: true, message: 'Обязательное поле' }]}
			>
				<TextArea rows={2} autoSize />
			</Form.Item>
			<Form.Item
				label="rjsf схема"
				labelCol={{ span: 24 }}
				name={["rjsf", "schema"]}
				rules={[{ required: true, message: 'Обязательное поле' }]}
			>
				<TextArea rows={2} autoSize />
			</Form.Item>
			<Form.Item
				label="rjsf_signer схема"
				labelCol={{ span: 24 }}
				name={["rjsf_signer", "schema"]}
				rules={[{ required: true, message: 'Обязательное поле' }]}
			>
				<TextArea rows={2} autoSize />
			</Form.Item>
			<Form.Item wrapperCol={{ span: 24 }}>
				<Button type="primary" htmlType="submit" disabled={loading} style={{marginRight: 5}}>
					{submitText}
				</Button>
				<Button type="ghost" disabled={loading} onClick={() => setIsOpen({
					type: action,
					payload: false
				})}>
					Отмена
				</Button>
			</Form.Item>
		</Form>
	)
}

export default DocTemplateEditor
