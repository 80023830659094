import { Button, Col, Row } from "antd"
import PlusCircleOutlined from '@ant-design/icons/PlusCircleOutlined';

const Heading = ({ title, buttonTitle, onClick }: HeadingType) => {
	return (
		<Row gutter={[10, 10]} justify="space-between" align="middle">
			<Col span={12} className={"heading-title"}>
				{title}
			</Col>
			<Col span={12} style={{ display: "flex", justifyContent: 'end' }}>
				{!!buttonTitle && <Button type="primary" onClick={onClick}>
					<PlusCircleOutlined />
					{buttonTitle}
				</Button>}
			</Col>
		</Row>
	)
}

export default Heading
