import { useRequest } from "ahooks";

import service from "@/api/HTTPClient";
import { useAuth } from "@/hooks/index";

const useUpdateUser = (id: number, options: ObjectRecord) => {

	const { client } = useAuth()

	const request = (data: UserDataType) => {

		if (id) {
			return new Promise((resolve, reject) => {
				// @ts-ignore
				service.put(`${client}/admin/users/${id}`, data)
					.then(response => resolve(response.status))
					.catch(reject)
			})
		}

		return Promise.reject(new Error('Ошибка обновления пользователя'))
	}
	// @ts-ignore
	return useRequest(request, options)
}

export default useUpdateUser;
