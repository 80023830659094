import { useRequest } from "ahooks";

import service from "@/api/HTTPClient";
import { useAuth } from "@/hooks/index";

const useDeleteDocTemplate = (options: ObjectRecord) => {

	const { client } = useAuth()

	const request = (id: any) => {
		if (id) {
			return new Promise((resolve, reject) => {
				service.delete(`${client}/admin/document_templates/${id}`)
					.then(response => resolve(response.status))
					.catch(reject)
			})
		}

		return Promise.reject(new Error('Ошибка удаления шаблона'))
	}
	// @ts-ignore
	return useRequest(request, options)
}

export default useDeleteDocTemplate;
