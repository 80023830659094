export enum Role {
	ADMIN = 'admin',
	CLIENT = 'client'
}

type State = {
	isAuth: boolean
	clients: {key: string}[]
	isLoading: boolean
	client: string
	formData: Login | any
}

type SetIsAuthAction = {
	type: 'SET_IS_AUTH'
	payload: boolean | any
}

type SetClients = {
	type: 'SET_CLIENTS'
	payload: {key: string}[]
}

type SetIsLoading = {
	type: 'SET_IS_LOADING'
	payload: boolean
}

type SetClientAction = {
	type: 'SET_CLIENT'
	payload: string
}

type Action = SetIsAuthAction | SetClients | SetIsLoading | SetClientAction

const authReducer = (state: State, {type, payload}: Action) => {

	switch (type) {
		case 'SET_IS_AUTH':
			return {
				...state,
				isAuth: payload
			}
		case 'SET_CLIENTS':
			return {
				...state,
				clients: payload
			}
		case 'SET_IS_LOADING':
			return {
				...state,
				isLoading: payload
			}
		case 'SET_CLIENT':
			return {
				...state,
				client: payload
			}
		default:
			return state
	}
}

export default authReducer;
