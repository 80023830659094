import { useRequest } from "ahooks";

import service from "@/api/HTTPClient"
import { useAuth } from "@/hooks/index";

const useDocuments = (options: ObjectRecord) => {
	const { client } = useAuth()

	const request = () => {
		if (client) {
			return new Promise((resolve, reject) => {
				service.get(`${client}/admin/documents`)
					.then(response => resolve(response?.data ?? []))
					.catch(reject)
			})
		}

		return Promise.reject(new Error('Ошибка получения документов'))
	}
	// @ts-ignore
	return useRequest(request, options)
}

export default useDocuments;
