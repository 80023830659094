import { useRequest } from "ahooks";
import service from "@/api/HTTPClient";

const useClients = (options: ObjectRecord) => {

	const request = () => {
		return new Promise((resolve, reject) => {
			service.get('clients')
				.then(response => resolve(response.data))
				.catch(reject)
		})
	}

	return useRequest(request, options)
}

export default useClients;
