type SetEditorInitValuesAction = {
	type: 'SET_EDITOR_INIT_VALUES'
	payload: UserDataType
}

type SetIsOpenCreateAction = {
	type: 'SET_IS_OPEN_CREATE'
	payload: boolean
}

type SetIsOpenChangeAction = {
	type: 'SET_IS_OPEN_CHANGE'
	payload: boolean
}

type Action =
	SetEditorInitValuesAction |
	SetIsOpenCreateAction |
	SetIsOpenChangeAction

type State = {
	editorInitValues: UserDataType
	isOpenCreate: boolean
	isOpenChange: boolean
	id: number | null
}

const userReducer = (state: State, {type, payload}: Action) => {
	switch (type) {
		case 'SET_EDITOR_INIT_VALUES':
			return {
				...state,
				editorInitValues: payload,
				id: payload.id,
			}
		case 'SET_IS_OPEN_CREATE':
			return {
				...state,
				isOpenCreate: payload
			}
		case 'SET_IS_OPEN_CHANGE':
			return {
				...state,
				isOpenChange: payload
			}
		default:
			return state
	}
}

export default userReducer;
