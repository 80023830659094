import React from 'react';
import ReactDOM from 'react-dom/client';
import './fonts/OpenSans/OpenSans-VariableFont_wdth,wght.ttf'

import App from './App';

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);
root.render(<App />);
